.background {
  background-image: url("/public/background.png");
  background-repeat: no-repeat;
  background-size: 140% 30%;
  height: 100vh;
}
.linkMenu {
  width: calc(100px + 5vw);
  color: #315499 !important;
  font-size: calc(12px + 0.6vw) !important;
  font-weight: 500;
  position: relative;
  text-decoration: none;
  padding: 10px 0;
  text-shadow:2px 2px 2px rgba(12, 0, 68, 0.051);
}
.linkMenu:after {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 0;
  height: 2px;
  background: #315499;
  transition: width 500ms ease;
}

.linkMenu:hover:after {
  width: 100%;
}

.menuDeroulant {
  text-align: center;
}
.structure {
  margin-top: calc(-25px + 7vh) !important;
  margin-left: 0.1vw !important;
  margin-bottom: 0.8vh !important;
  width: 100%;
}
.paragraphe {
  padding-left: 10%;
  padding-right: 10%;
  border: 2px #9b9b9b solid;
  border-radius: 25px;
  font-size: calc(6px + 0.7vw);
  color: #315499;
  margin-bottom: 80px !important;
}
.icone {
  margin-right: 3%;
  max-height: 80%;
}
.contact {
  height: 10vh;
  margin-top: calc(14px + 0.1vh) !important;
  display: flex;
  max-width: 80% !important;
  margin-left: 15% !important;
}
h1 {
  color: #315499;
  font-size: calc(9px + 0.75vw + 0.75vh) !important;
}
h2 {
  color: #3f8853;
  font-size: calc(8px + 0.5vw + 0.5vh) !important;
}
h3 {
  color: #315499;
  font-size: calc(7px + 0.4vw + 0.4vh) !important;
}
h4 {
  color: #3f8853;
  font-size: calc(4px + 0.25vw + 0.25vh) !important;
}
h5 {
  color: #315499;
  margin-bottom: -500px;
  font-size: calc(3px + 0.25vw + 0.25vh) !important;
}
a {
  color: rgb(201, 255, 201);
  font-size: calc(6px + 0.25vw + 0.25vh) !important;
}
.fade-enter {
  opacity: 0;
  z-index: 1;
}

.fade-enter.fade-enter-active {
  opacity: 1;
  transition: opacity 250ms ease-in;
  position: absolute;
}
.fade-exit {
  opacity: 0;
  transition: opacity 150ms ease-in;
  position: absolute;
}
.button {
  color: #315499;
  border: 1px solid #315499;
  border-radius: 12px;
  padding: 10px;
  text-decoration: none;
  display: inline-block;
  width: 10%;
  min-width: 75px;
  background-color: #ffffff;
}
.button:hover {
  color: #3f8853;
  border: 1px solid #3f8853;
}

.cards {
  max-height: calc(100px + 10vh);
  min-width: calc(100px + 5vh) ;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
  margin: 20px;
  border-radius: 20px;
  border: 2px #9b9b9b solid;
}

.cards:hover {
  max-height: calc(100px + 10vh);
  min-width: calc(100px + 5vh);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
  margin: 20px;
  border-radius: 20px;
  background-color: #84abff17;
  opacity:0.8;
  cursor: pointer;
}
.fade.show {
  background: rgba(0, 0, 0, 0.147); 
  opacity: 1;
}
.alertSuccess{
  background-color: white;
  color: green;
  margin-bottom: 2%;
}
.alertError{
  background-color: white;
  color: red;
  margin-bottom: 2%;
}
@media (min-width: 2000px) {
  .header {
    margin-left: calc(15px + 25vw) !important;
  }
}

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type="number"] {
  -moz-appearance: textfield;
}
body {
  overflow-y: scroll !important;
  }